/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.button-collapse').sideNav();
        $('.parallax').parallax();
        $('.form_field_wrap').addClass('input-field');
        $('#pirate-forms-contact-submit').addClass('waves-effect waves-red btn red darken-2 center-align z-depth-2').css('width', '50%');
        //$('#submit').addClass('red darken-2');
        $('#pirate-forms-contact-message, #comment').addClass('materialize-textarea');
        $('#commentform p').addClass('input-field');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Sentence Scrambler
    'sentence_scrambler': {
      init: function() {
        function shuffle(array) {
          var currentIndex = array.length, temporaryValue, randomIndex;

          // While there remain elements to shuffle...
          while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
          }

          return array;
        }
        $('#submitbutton').click(function( event ) {
          event.preventDefault();
          var textBox = $('#texttoscramble').val();
          if (textBox === "") {
            alert("Please enter a sentence!");
          } else {
            // Run gif loading animation
            $(".se-pre-con").fadeIn("fast").delay( 1000 );
            // Split at space char all the words
            var words = textBox.split(" ");
            // Set textarea to empty
            $('#texttoscramble').val();
            // If it's checked
            if ($('#decapitalise').prop('checked')) {
              // Grab the first word & make it lowercase
              var first =  words.shift().toLowerCase();
              // Hand it back to our array of words
              words.push(first);
            }
            // Run our shuffle function
            shuffle(words);
            // Pass the shuffled words back to the textarea
            $('#texttoscramble').val(words.join(" "));
            // Fade out from gif
            $(".se-pre-con").fadeOut("slow");
          }
        });
      }
    },
    // What Da Word.
    'whats_the_word': {
      init: function() {
        $('#word').focus(function(){
          var full = $("#example").has("p").length ? true : false;
          if(full === false){
            $('#example').empty();
          }
        });

        $('#search').click(function( event ) {
          event.preventDefault();
          console.log("shit happened!");
        });

        var getExample = function(){

            var word = $('#word').val();
            if ($('#first-letter').val() !== '') {
                firstLetter = "&sp=" + $('#first-letter').val() + "*";
            } else {
                firstLetter = '';
            }
            if ($('#last-letter').val() !== '') {
                lastLetter = "&sp=*" + $('#last-letter').val();
            } else {
                lastLetter = '';
            }

            if(word === ''){

                $('#example').html("<h2 class='loading'>You didn't enter a word!</h2>");

            } else {

                $('#example').html("<h2 class='loading'>Loading...</h2>");

                $.getJSON("http://api.datamuse.com/words?rd=" + word + firstLetter + lastLetter, function(json) {
                    if (jQuery.isEmptyObject(json)){
                      $('#example').html('<h2 class="loading">Apologies! Something went wrong!');
                    } else {
                        var words = [];
                        //var urlSafeWords = []
                        //var wList = $('#example ul');
                        $.each(json, function(index, value) {
                          var eachWord = value.word;
                            words.push(eachWord);
                            //var urlSafe = eachWord.replace(/ /g, '+');
                           //urlSafeWords.push(urlSafe);
                        });
                        var list = '<ul class="word-list"><li>' + words.join('</a></li><li>') + '</li></ul>';
                        $('#example').html(list);
                    }
                 });

            }

            return false;
        };

        $('#search').click(getExample);
        $('#word').keyup(function(event){
            if(event.keyCode === 13){
                getExample();
            }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
